// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import HomePage from './components/mainPage/mainPage';
import CryptoPage from './components/CryptoDetailsPage/CryptoDetailsPage';
import ExchangePage from './components/exchangePage/page';
import { useTelegram } from './components/hooks/useTelegram';
import RefPage from './components/refSystemPage/page';
import History from './components/transactionHisroty/history';
import Balance from './components/balanceMenu/balaceMenu';
import Crypto from './components/cryptoMenu/crypto';
import Desposit from './components/depositMenu/deposit';
import Invoice from './components/depositInfoMenu/depositInfo';
import BalanceExchanger from './components/balanceExchanger/balanceExchanger';
import BalanceCryptoDetailsPage from './components/BalanceCryptoDetailsPage/BalanceCryptoDetailsPage';
import WithdrawPage from './components/WithdrawPage/WithdrawPage';


const App = () => {
  const {tg, onToggleButton} = useTelegram()

  useEffect( () => {
    tg.ready();
  }, [])

  return (
    <Router>
      <div className="App">
        <Routes>
          {/* Главная страница */}
          <Route path="/" element={<HomePage />} />

          <Route path="/ref" element={<RefPage />} />

          <Route path="/crypto-details" element={<CryptoPage />} />

          <Route path="/exchange" element={<ExchangePage />} />

          <Route path="/history" element={<History />} />

          <Route path="/profile" element={<Balance />} />

          <Route path="/crypto" element={<Crypto />} />

          <Route path="/deposit" element={<Desposit />} />

          <Route path="/invoice" element={<Invoice />} />

          <Route path="/balanceExchanger" element={<BalanceExchanger />} />

          <Route path="/balanceCryptoDetails" element={<BalanceCryptoDetailsPage />} />

          <Route path="/withdraw" element={<WithdrawPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
