import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../hooks/useTelegram";
import styles from './BalanceMenu.module.css';

// Функция для форматирования чисел
const formatAmount = (amount) => {
    if (typeof amount !== "number") {
        return 0; // Возвращаем 0, если amount не число
    }
    if (Math.abs(amount) < 1) {
        return parseFloat(amount.toFixed(5)); // До 5 знаков, если меньше 1
    }
    return parseFloat(amount.toFixed(2)); // До 2 знаков, если больше 1
};

function App() {
    const [balance, setBalance] = useState(null);
    const navigate = useNavigate();
    const tg = useTelegram();

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru"; // Переход на just-trade.ru
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = "#2e3440";
        return () => {
            document.body.style.backgroundColor = ""; // Очищаем стиль при размонтировании
        };
    }, []);

    const [userID, setUserID] = useState(tg.user?.id || null);

    useEffect(() => {
        const fetchBalance = async () => {
            if (!userID || !tg.saveData) {
                return;
            }

            try {
                const url = `https://api.just-trade.ru/api/balance`;
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        initData: tg.saveData,
                        userID: userID,
                    }),
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setBalance(data);
            } catch (error) {
            }
        };

        fetchBalance();
    }, [userID, tg.saveData]);

    // Обработчик клика для перехода на страницу криптовалюты
    const handleCurrencyClick = (code) => {
        navigate(`/crypto?getInfo=${code}`);
    };

    if (!balance) return <p className={styles.balanceLoadingProfileMenu}>Загрузка...</p>;

    return (
        <div className={styles.balanceContainerProfileMenu}>
            <h1 className={styles.balanceBalanceTitleProfileMenu}>
                <span className={styles.balanceDollarSignProfileMenu}>$</span>
                {formatAmount(balance.totalBalanceUSD)}
            </h1>
            <p className={styles.balanceBalanceSubtitleProfileMenu}>
                Весь баланс в <span>USD</span>
            </p>

            <div className={styles.balanceButtonsProfileMenu}>
                <button className={styles.balanceButton} onClick={() => (window.location.href = "https://just-trade.ru/deposit")}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/bank-pic.png"
                        alt="Пополнить"
                        className={styles.balanceButtonIconProfileMenu}
                    />
                    Пополнить
                </button>
                <button className={styles.balanceButton} onClick={() => (window.location.href = `https://just-trade.ru/balanceExchanger?sendCryptoID=2&getCryptoID=1`)}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/exchanger-pic.png"
                        alt="Обменять"
                        className={styles.balanceButtonIconProfileMenu}
                    />
                    Обменять
                </button>
            </div>

            <h2 className={styles.balanceSectionTitleProfileMenu}>Средства</h2>
            <div className={styles.balanceCurrencyListProfileMenu}>
                {balance.currencies.map((currency) => (
                    <div
                        key={currency.code}
                        className={styles.balanceCurrencyProfileMenu}
                        onClick={() => handleCurrencyClick(currency.code)}
                        style={{ cursor: "pointer" }}
                    >
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%" }}>
                            <img
                                src={currency.icon}
                                alt={currency.code}
                                className={styles.balanceCurrencyIconProfileMenu}
                            />
                            <div className={styles.balanceCurrencyInfoProfileMenu}>
                                <p
                                    className={styles.balanceCurrencyNameProfileMenu}
                                    style={{ color: currency.color }}
                                >
                                    {currency.code}
                                </p>
                            </div>
                            <div className={styles.balanceCurrencyAmountProfileMenu}>
                                <span className={styles.balanceUsdValueProfileMenu}>
                                    {formatAmount(currency.amount)} {currency.code}
                                </span>
                                <span className={styles.balanceAmountTextProfileMenu}>
                                    ${formatAmount(currency.usdValue)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default App;