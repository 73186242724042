import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTelegram } from '../hooks/useTelegram';
import styles from './WithdrawPage.module.css';

function WithdrawPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const withdrawCryptoId = queryParams.get('withdraw');
    const tg = useTelegram();

    const [cryptoData, setCryptoData] = useState(null);
    const [networks, setNetworks] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [walletAddress, setWalletAddress] = useState('');
    const [amount, setAmount] = useState(0);
    const [balance, setBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [generalError, setGeneralError] = useState(''); // Для общих ошибок
    const [walletError, setWalletError] = useState(''); // Для ошибки валидации кошелька
    const [minAmountError, setMinAmountError] = useState('');
    const [balanceError, setBalanceError] = useState(''); // Новая ошибка для недостатка средств
    const [isNetworkDropdownOpen, setIsNetworkDropdownOpen] = useState(false);

    const amountRef = useRef(null);
    const amountSpanRef = useRef(null);

    // Загрузка данных о криптовалюте
    useEffect(() => {
        const fetchCryptoData = async () => {
            try {
                if (!withdrawCryptoId) {
                    setGeneralError('Идентификатор криптовалюты не указан');
                    setLoading(false);
                    return;
                }
                const response = await fetch(`https://api.just-trade.ru/api/wallet/crypto/${withdrawCryptoId}`);
                if (!response.ok) throw new Error('Криптовалюта не найдена');
                const data = await response.json();
                setCryptoData(data);
                setAmount(0);
            } catch (err) {
                setGeneralError(err.message);
            }
        };
        fetchCryptoData();
    }, [withdrawCryptoId]);

    // Загрузка баланса пользователя
    useEffect(() => {
        const fetchUserBalance = async () => {
            try {
                if (!withdrawCryptoId) return;
                const response = await fetch(
                    `https://api.just-trade.ru/api/user_balances?user_id=${tg.user.id}&crypto_id=${withdrawCryptoId}`
                );
                if (!response.ok) throw new Error('Не удалось загрузить баланс');
                const data = await response.json();
                setBalance(data.amount || 0);
            } catch (err) {
                setGeneralError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchUserBalance();
    }, [withdrawCryptoId]);

    // Загрузка сетей
    useEffect(() => {
        const fetchNetworks = async () => {
            if (!cryptoData?.code) return;
            try {
                const response = await fetch('https://api.just-trade.ru/api/networks', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ crypto: cryptoData.code }),
                });
                if (!response.ok) throw new Error('Сети не найдены');
                const data = await response.json();
                setNetworks(data);
                setSelectedNetwork(data[0]?.network_code || null);
            } catch (err) {
                setGeneralError(err.message);
            }
        };
        fetchNetworks();
    }, [cryptoData]);

    const handleBackClick = () => {
        window.location.href = 'https://just-trade.ru/profile';
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = '#2E3440';
        return () => {
            document.body.style.backgroundColor = '';
        };
    }, []);

    const adjustInputWidth = (value, inputRef, spanRef) => {
        if (spanRef.current && inputRef.current) {
            spanRef.current.textContent = value;
            const spanWidth = spanRef.current.offsetWidth;
            inputRef.current.style.width = `${spanWidth + 15}px`;
        }
    };

    const handleAmountChange = (e) => {
        let value = e.target.value;
        if (value === '.' || value === ',') value = '0.';
        if (value.includes(',')) value = value.replace(',', '.');
        if (value.startsWith('0') && !value.includes('.')) value = value.replace(/^0+/, '');
        const numericValue = parseFloat(value);

        if (!isNaN(numericValue) && /^[0-9]*\.?[0-9]*$/.test(value)) {
            setAmount(value);
            adjustInputWidth(value, amountRef, amountSpanRef);
            
            // Проверка минимальной суммы
            const minAmount = 1 / (cryptoData?.rate || 1);
            if (numericValue < minAmount) {
                setMinAmountError(`Минимальная сумма вывода: ${minAmount.toFixed(6)} ${cryptoData?.code || ''}`);
            } else {
                setMinAmountError('');
            }

            // Проверка баланса
            if (numericValue > balance) {
                setBalanceError(`Недостаточно средств, ваш баланс: ${balance} ${cryptoData?.code || ''}`);
            } else {
                setBalanceError('');
            }
        } else {
            setAmount(0);
            adjustInputWidth('0', amountRef, amountSpanRef);
            setMinAmountError('');
            setBalanceError('');
        }
    };

    const handleWalletChange = (e) => {
        setWalletAddress(e.target.value);
        if (!e.target.value.trim() && amount > 0) {
            setWalletError('Пожалуйста, введите адрес внешнего кошелька');
        } else {
            setWalletError('');
        }
    };

    const toggleNetworkDropdown = () => {
        setIsNetworkDropdownOpen(!isNetworkDropdownOpen);
    };

    const handleNetworkSelect = (networkCode) => {
        setSelectedNetwork(networkCode);
        setIsNetworkDropdownOpen(false);
    };

    const handleWithdraw = async () => {
        if (!walletAddress.trim()) {
            setWalletError('Пожалуйста, введите адрес внешнего кошелька');
            return;
        }
        if (minAmountError || balanceError) return; // Блокируем вывод, если есть ошибки

        const payload = {
            cryptoId: withdrawCryptoId,
            amount: parseFloat(amount),
            network: selectedNetwork,
            walletAddress,
            userId: tg.user.id,
        };

        try {
            alert('В разработке'); // Уведомление о том, что функционал в разработке
        } catch (err) {
            setGeneralError(err.message);
        }
    };

    // Функция для установки полного баланса
    const setFullBalance = () => {
        const balanceValue = balance.toString();
        setAmount(balanceValue);
        adjustInputWidth(balanceValue, amountRef, amountSpanRef);
        const minAmount = 1 / (cryptoData?.rate || 1);
        if (parseFloat(balanceValue) < minAmount) {
            setMinAmountError(`Минимальная сумма вывода: ${minAmount.toFixed(6)} ${cryptoData?.code || ''}`);
        } else {
            setMinAmountError('');
        }
        // При установке полного баланса ошибка недостатка средств не должна появляться
        setBalanceError('');
    };

    if (loading) return <p className={styles.loading}>Загрузка...</p>;

    const selectedNetworkData = networks.find((network) => network.network_code === selectedNetwork);

    return (
        <div className={styles.withdrawContainer}>
            <div className={styles.contentWrapper}>
                <div className={styles.header}>
                    <h1 className={styles.title}>Вывод</h1>
                    <p className={styles.subtitle}>
                        Выберите верную сеть, <span style={{ color: "#FC3F3F" }}>иначе монеты будут безвозвратно утеряны</span>
                    </p>
                </div>
                {generalError && (
                    <p className={styles.errorTop}>{generalError}</p>
                )}
                {!cryptoData && !generalError && (
                    <p className={styles.errorTop}>Криптовалюта не найдена</p>
                )}
                {cryptoData && (
                    <div className={styles.cardWrapper}>
                        <div className={styles.card}>
                            <div className={styles.cardItem}>
                                <label className={styles.label}>Отправляете</label>
                                <div className={styles.inputRow}>
                                    <div className={styles.cryptoInfo}>
                                        <img
                                            src={cryptoData.icon}
                                            alt={cryptoData.code}
                                            className={styles.iconImage}
                                            onError={(e) => (e.target.src = 'https://via.placeholder.com/40')}
                                        />
                                        <span className={styles.cryptoName}>{cryptoData.code}</span>
                                    </div>
                                    <input
                                        type="text"
                                        value={amount}
                                        onChange={handleAmountChange}
                                        className={styles.inputAmount}
                                        ref={amountRef}
                                        style={{ minWidth: '80px' }}
                                    />
                                    <span ref={amountSpanRef} className={styles.hiddenSpan}></span>
                                </div>
                                {minAmountError && <span className={styles.errorText}>{minAmountError}</span>}
                                {balanceError && <span className={styles.errorText}>{balanceError}</span>}
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.cardItem}>
                                <label className={styles.label}>Сеть</label>
                                <div className={styles.networkDropdown}>
                                    <div className={styles.networkSelected} onClick={toggleNetworkDropdown}>
                                        {selectedNetworkData ? (
                                            <>
                                                <img
                                                    src={selectedNetworkData.icon || 'https://via.placeholder.com/20'}
                                                    alt={selectedNetworkData.network_code}
                                                    className={styles.networkIcon}
                                                />
                                                <span>{selectedNetworkData.network_code}</span>
                                            </>
                                        ) : (
                                            <span>Выберите сеть</span>
                                        )}
                                        <span className={styles.dropdownArrow}>▼</span>
                                    </div>
                                    {isNetworkDropdownOpen && (
                                        <div className={styles.networkOptions}>
                                            {networks.map((network) => (
                                                <div
                                                    key={network.network_id}
                                                    className={styles.networkOption}
                                                    onClick={() => handleNetworkSelect(network.network_code)}
                                                >
                                                    <img
                                                        src={network.icon || 'https://via.placeholder.com/20'}
                                                        alt={network.network_code}
                                                        className={styles.networkIcon}
                                                    />
                                                    <span>{network.network_code}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.cardItem}>
                                <label className={styles.label}>Адрес внешнего кошелька</label>
                                <textarea
                                    value={walletAddress}
                                    onChange={handleWalletChange}
                                    placeholder="Введите адрес кошелька"
                                    className={styles.textArea}
                                />
                                {walletError && <span className={styles.errorText}>{walletError}</span>}
                            </div>
                        </div>
                        <div className={styles.card}>
                            <div className={styles.cardItem}>
                                <div className={styles.balanceContainer} onClick={setFullBalance}>
                                    <p className={styles.balanceText}>Баланс: {balance} {cryptoData.code}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/* Кнопка возвращена в конец контейнера с фиксированным положением */}
            <div className={styles.buttonContainer}>
                <button className={styles.withdrawButton} onClick={handleWithdraw}>
                    Вывести
                </button>
            </div>
        </div>
    );
}

export default WithdrawPage;