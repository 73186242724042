import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTelegram } from "../hooks/useTelegram";
import styles from "./Crypto.module.css"; // Импортируем как CSS-модуль
import { debounce } from "lodash";

// Функция для форматирования чисел
const formatAmount = (amount) => {
    if (typeof amount !== "number") {
        return 0; // Возвращаем 0, если amount не число
    }
    if (Math.abs(amount) < 1) {
        return parseFloat(amount.toFixed(5));
    }
    return parseFloat(amount.toFixed(2));
};

// Компонент для истории транзакций
const TransactionHistory = ({ userID, currency }) => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const tg = useTelegram();

    const fetchHistory = useCallback(async () => {
        if (loading || !hasMore) {
            return;
        }

        setLoading(true);
        try {
            const response = await fetch("https://api.just-trade.ru/get_user_history", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    initData: tg.saveData,
                    page: page,
                    limit: 10,
                    hash: tg.saveData,
                    userID: userID,
                    currency: currency || undefined,
                }),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            if (result.history && result.history.length > 0) {
                setTransactions((prev) => {
                    const newTransactions = result.history.filter(
                        (item) => !prev.some((prevItem) => prevItem.id === item.id)
                    );
                    return [...prev, ...newTransactions];
                });
                setPage((prev) => prev + 1);
                setHasMore(result.history.length === 10); // Предполагаем, что limit = 10
            } else {
                setHasMore(false);
            }
        } catch (err) {
            setHasMore(false);
        } finally {
            setLoading(false);
        }
    }, [loading, hasMore, page, tg.saveData, userID, currency]);

    useEffect(() => {
        fetchHistory();
    }, [fetchHistory]);

    const handleScroll = useCallback(
        debounce(() => {
            if (!hasMore || loading) {
                return;
            }

            const scrollBottom =
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 50;

            if (scrollBottom) {
                fetchHistory();
            }
        }, 300),
        [hasMore, loading, fetchHistory]
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [handleScroll]);

    const groupByDate = (transactions) => {
        return transactions.reduce((acc, transaction) => {
            const date = new Date(transaction.date).toLocaleDateString("ru-RU", {
                day: "numeric",
                month: "long",
                year: "numeric",
            });
            acc[date] = acc[date] || [];
            acc[date].push(transaction);
            return acc;
        }, {});
    };

    const groupedTransactions = groupByDate(transactions);

    return (
        <div className={styles.getInfoTransactionHistoryCryptoMenu}>
            <h2 className={styles.getInfoTransactionHeaderCryptoMenu}>История транзакций</h2>
            {transactions.length === 0 && !loading && !hasMore && (
                <div className={styles.getInfoNoTransactionsCryptoMenu}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/archive-pic.png"
                        alt="No transactions"
                        className={styles.getInfoNoTransactionsIconCryptoMenu}
                    />
                    <p>Здесь будут отображаться все ваши транзакции...</p>
                </div>
            )}
            {Object.keys(groupedTransactions).map((date) => (
                <div key={date}>
                    <div className={styles.getInfoDateHeaderCryptoMenu}>{date}</div>
                    {groupedTransactions[date].map((item, index) => (
                        <div key={item.id || index} className={styles.getInfoTransactionCryptoMenu}>
                            <div className={styles.getInfoTransactionIconCryptoMenu}>⭐</div>
                            <div className={styles.getInfoTransactionContentCryptoMenu}>
                                <div className={styles.getInfoTransactionPathCryptoMenu} title={item.path}>
                                    {item.path}
                                </div>
                                <div className={styles.getInfoTransactionDateCryptoMenu}>
                                    {new Date(item.date).toLocaleTimeString("ru-RU", {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                    })}
                                </div>
                            </div>
                            <div className={styles.getInfoAmountsCryptoMenu}>
                                <div
                                    className={item.getAmount >= 0 ? styles.getInfoPositiveCryptoMenu : styles.getInfoNegativeCryptoMenu}
                                >
                                    {formatAmount(item.getAmount)} {item.get}
                                </div>
                                <div className={styles.getInfoSmallTextCryptoMenu}>
                                    -{formatAmount(item.sendAmount)} {item.send}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            {loading && <p className={styles.getInfoLoadingTextCryptoMenu}>Загрузка...</p>}
            {!hasMore && transactions.length > 0 && (
                <p className={styles.getInfoLoadingTextCryptoMenu}>Все транзакции загружены</p>
            )}
        </div>
    );
};

function Crypto() {
    const [balance, setBalance] = useState({ totalBalanceUSD: 0, currencies: [] });
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tg = useTelegram();
    const { search } = useLocation();

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru/profile"; // Переход на just-trade.ru
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = "#2e3440";
        return () => {
            document.body.style.backgroundColor = ""; // Очищаем стиль при размонтировании
        };
    }, []);

    // Получаем валюту из URL
    useEffect(() => {
        const urlParams = new URLSearchParams(search);
        const currencyParam = urlParams.get("getInfo");
        if (currencyParam) {
            setCurrency(currencyParam.toUpperCase());
        }
    }, [search]);

    // Запрос баланса с userID
    const [userID, setUserID] = useState(tg.user?.id || null);
    useEffect(() => {
        const fetchBalance = async () => {
            if (!tg.saveData || !userID) {
                setError("initData или userID не определены");
                setLoading(false);
                return;
            }

            try {
                const url = `https://api.just-trade.ru/api/balance`;
                const response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        initData: tg.saveData,
                        userID: userID,
                    }),
                });
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                const safeData = {
                    totalBalanceUSD: data.totalBalanceUSD || 0,
                    currencies: Array.isArray(data.currencies)
                        ? data.currencies.map((c) => ({
                              ...c,
                              icon: c.icon || "", // Просто оставляем icon как есть
                          }))
                        : [],
                };
                setBalance(safeData);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBalance();
    }, [tg.saveData, userID]);

    // Находим текущую валюту
    const currentCurrency = balance.currencies.find((c) => c.code === currency) || {
        code: currency || "UNKNOWN",
        name: currency || "Unknown",
        amount: 0,
        usdValue: 0,
        icon: "https://via.placeholder.com/80",
        color: "#ffffff",
    };

    if (loading) return <p className={styles.getInfoLoadingTextCryptoMenu}>Загрузка...</p>;
    if (error) return <p className={styles.getInfoLoadingTextCryptoMenu}>Ошибка: {error}</p>;
    if (!currency) return <p className={styles.getInfoLoadingTextCryptoMenu}>Валюта не выбрана</p>;

    return (
        <div className={styles.getInfoContainerCryptoMenu}>
            <img
                src={currentCurrency.icon}
                alt={currency}
                className={styles.getInfoCurrencyLogoCryptoMenu}
                onError={(e) => {
                    if (e.target.src !== "https://via.placeholder.com/80") {
                        e.target.src = "https://via.placeholder.com/80";
                    }
                }}
            />
            <h1 className={styles.getInfoBalanceTitleCryptoMenu}>
                Ваш <span style={{ color: currentCurrency.color }}>{currency}</span> баланс
            </h1>
            <p className={styles.getInfoBalanceAmountCryptoMenu}>
                {formatAmount(currentCurrency.amount)}{" "}
                <span style={{ color: currentCurrency.color }}>{currency}</span>
            </p>
            <p className={styles.getInfoBalanceUsdCryptoMenu}>~ {formatAmount(currentCurrency.usdValue)} USD</p>

            <div className={styles.getInfoButtonsCryptoMenu}>
                <button className={styles.cryptoButton} onClick={() => (window.location.href = `https://just-trade.ru/withdraw?withdraw=${currentCurrency.crypto_id}`)}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/card-pic.png"
                        alt="Вывести"
                        className={styles.getInfoButtonIconCryptoMenu}
                    />
                    Вывести
                </button>
                <button className={styles.cryptoButton} onClick={() => (window.location.href = `https://just-trade.ru/deposit?crypto=${currentCurrency.code}`)}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/bank-pic.png"
                        alt="Пополнить"
                        className={styles.getInfoButtonIconCryptoMenu}
                    />
                    Пополнить
                </button>
                <button className={styles.cryptoButton} onClick={() => (window.location.href = `https://just-trade.ru/balanceExchanger?sendCryptoID=${currentCurrency.crypto_id}&getCryptoID=1`)}>
                    <img
                        src="https://api.just-trade.ru/api/public/images/pic/exchanger-pic.png"
                        alt="Обменять"
                        className={styles.getInfoButtonIconCryptoMenu}
                    />
                    Обменять
                </button>
            </div>

            <TransactionHistory userID={userID} currency={currency} />
        </div>
    );
}

export default Crypto;