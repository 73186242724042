import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import '../CryptoDetailsPage/crypto.css';
import { useTelegram } from '../hooks/useTelegram';

function BalanceCryptoDetailsPage() {
    const [cryptos, setCryptos] = useState([]);
    const [userBalances, setUserBalances] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tg = useTelegram();

    const getCryptoID = queryParams.has('getCryptoID') ? queryParams.get('getCryptoID') : 2;
    const sendCryptoID = queryParams.has('sendCryptoID') ? queryParams.get('sendCryptoID') : null;
    // Используем параметр change из URL, по умолчанию 'get'
    const change = queryParams.get('change') || 'get';

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru/profile"; // Переход на just-trade.ru
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = '#000';
    }, []);

    // Загружаем список криптовалют
    useEffect(() => {
        const fetchCryptos = async () => {
            try {
                const response = await fetch('https://api.just-trade.ru/api/cryptocurrencies');
                if (!response.ok) throw new Error('Ошибка при загрузке криптовалют');
                const data = await response.json();
                setCryptos(data);
            } catch (error) {
                console.error('Ошибка при загрузке криптовалют:', error);
            }
        };
        fetchCryptos();
    }, []);

    // Загружаем баланс пользователя для всех криптовалют
    useEffect(() => {
        const fetchUserBalances = async () => {
            try {
                const initData = tg.saveData;
                const response = await fetch(
                    `https://api.just-trade.ru/api/balance?userID=${tg.user.id}&initData=${encodeURIComponent(initData)}`,
                    {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ userID: tg.user.id, initData }),
                    }
                );
                if (!response.ok) throw new Error('Ошибка при загрузке баланса');
                const data = await response.json();
                setUserBalances(data.currencies || []);
            } catch (error) {
                console.error('Ошибка при загрузке баланса:', error);
            }
        };
        if (tg.user?.id) {
            fetchUserBalances();
        }
    }, [tg.user?.id, tg.saveData]);

    // Фильтрация криптовалют на основе строки поиска
    const filteredCryptos = cryptos.filter(crypto =>
        crypto.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="App">
            <h1 className="Header">
                {change === 'send' ? 'Выберите криптовалюту для отправки' : 'Выберите криптовалюту для получения'}
            </h1>

            <input
                type="text"
                placeholder="Введите название криптовалюты..."
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            <ul className="crypto-list">
                {filteredCryptos.map(crypto => {
                    const balance = userBalances.find(b => b.code === crypto.code) || { amount: 0, usdValue: 0 };
                    return (
                        <li key={crypto.crypto_id}>
                            <Link
                                to={`/balanceExchanger?sendCryptoID=${
                                    change === 'send' ? crypto.crypto_id : sendCryptoID
                                }&getCryptoID=${
                                    change === 'get' ? crypto.crypto_id : getCryptoID
                                }`}
                                className="crypto-link"
                            >
                                <img src={crypto.icon} alt={crypto.name} width="50" height="50" />
                                <div className="crypto-info">
                                    <h2 className={crypto.name}>{crypto.name}</h2>
                                    <p>{crypto.description}</p>
                                    <span className="network">
                                        Баланс: {balance.amount} {crypto.code} (~{balance.usdValue} USD)
                                    </span>
                                </div>
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default BalanceCryptoDetailsPage;