// src/App.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, Link } from 'react-router-dom';
import "./crypto.css";
import { useTelegram } from "../hooks/useTelegram";

const CryptoDetailsPage = () => {
    const [cryptos, setCryptos] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // Стейт для строки поиска
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const tg = useTelegram();

    const getCryptoID = queryParams.has('getCryptoID') ? queryParams.get('getCryptoID') : 2;
    const sendCryptoID = queryParams.has('sendCryptoID') ? queryParams.get('sendCryptoID') : NaN;

    useEffect(() => {
        document.body.style.backgroundColor = '#000';
    }, []);

    const exchangePath = queryParams.get('exchangePath');
    const change = (isNaN(sendCryptoID)) ? 'send' : 'get';

    useEffect(() => {
        axios.get('https://api.just-trade.ru/api/cryptos', {
            params: { exchangePath, change }
        })
            .then(response => {
                setCryptos(response.data.data);
            })
            .catch(error => {
            });
    }, [exchangePath, change]);

    const currency = queryParams.get('exchangePath');

     // Обработчик для кнопки "Назад"
    const handleBackClick = () => {
        window.location.href = window.location.origin; // Перенаправление на начальную страницу
    };

    useEffect(() => {
        if (tg.BackButton) {
            // Показываем кнопку "Назад" в Telegram WebApp
            tg.BackButton.show();

            // Устанавливаем обработчик клика на кнопку
            tg.BackButton.onClick(handleBackClick);
        }
    }, [tg]);

    // const handleBackClick = () => {
    //     window.location.href = window.location.origin;
    // };

    // Фильтрация криптовалют на основе строки поиска
    const filteredCryptos = cryptos.filter(crypto =>
        crypto.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="App">
            <h1 className="Header">Выберите крипту, в которой будете оплачивать</h1>

            {/* Back button */}
            {/* <button className="back-button" onClick={handleBackClick}>
                Назад
            </button> */}

            {/* Поле для поиска */}
            <input
                type="text"
                placeholder="Введите название криптовалюты..."
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Обновление строки поиска
            />

            <ul className="crypto-list">
                {filteredCryptos.map(crypto => (
                    <li key={crypto.id}>
                        <Link
                            to={`/exchange?exchangePath=${currency}&sendCryptoID=${isNaN(sendCryptoID) ? crypto.id : sendCryptoID}&getCryptoID=${!isNaN(sendCryptoID) ? crypto.id : getCryptoID}`}
                            className="crypto-link"
                        >
                            <img src={crypto.image_url} alt={crypto.name} width="50" height="50" />
                            <div className="crypto-info">
                                <h2 className={crypto.name}>{crypto.name}</h2>
                                <p>{crypto.description}</p>
                                <span className="network">( CryptoBot )</span>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default CryptoDetailsPage;
