import React, { useEffect, useState, useCallback } from 'react';
import './history.css';
import { useTelegram } from "../hooks/useTelegram";

// Функция для форматирования чисел до 2 знаков после запятой
const formatAmount = (amount) => {
    if (Math.abs(amount) < 1) {
        // Если число меньше 1, округляем до 5 знаков после запятой
        return parseFloat(amount.toFixed(5));
    }
    return parseFloat(amount.toFixed(2));  // Округляем до 2 знаков после запятой
};

const TransactionHistory = () => {
    const [transactions, setTransactions] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const tg = useTelegram();

    const handleBackClick = () => {
        window.location.href = window.location.origin; // Переход на just-trade.ru
    };
        
    useEffect(() => {
        if (tg.BackButton) {
            // Показываем кнопку "Назад" в Telegram WebApp
            tg.BackButton.show();

            // Устанавливаем обработчик клика на кнопку
            tg.BackButton.onClick(handleBackClick);
        }
    }, [tg]);

    const fetchHistory = useCallback(async () => {
        if (loading || !hasMore) return;

        setLoading(true);
        try {
            const response = await fetch('https://api.just-trade.ru/get_user_history', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    initData: tg.saveData,
                    userID: tg.user.id,
                    page: page,
                    limit: 10,
                }),
            });

            const result = await response.json();

            if (result.history.length > 0) {
                // Проверка на дубликаты
                setTransactions((prev) => {
                    const newTransactions = result.history.filter(
                        (item) => !prev.some((prevItem) => prevItem.id === item.id)
                    );
                    return [...prev, ...newTransactions];
                });
                setPage((prev) => prev + 1);
            } else {
                setHasMore(false); // Отключаем дальнейшие запросы
            }
        } catch (err) {
            console.error('Ошибка при загрузке данных:', err);
        } finally {
            setLoading(false); // Сбрасываем флаг загрузки
        }
    }, [page, loading, hasMore]);

    useEffect(() => {
        fetchHistory(); // Загружаем первую порцию данных при монтировании
    }, []); // Убедимся, что useEffect вызывает fetchHistory только один раз
    
    const handleScroll = () => {
        if (!hasMore || loading) return; // Если нет данных для загрузки или уже идёт загрузка, выходим
    
        const scrollBottom =
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 50; // Небольшой запас
    
        if (scrollBottom) {
            fetchHistory(); // Загружаем следующую порцию
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMore]);

    // Функция для группировки по датам
    const groupByDate = (transactions) => {
        return transactions.reduce((acc, transaction) => {
            const date = new Date(transaction.date).toLocaleDateString('ru-RU', {
                day: 'numeric',
                month: 'long',
                year: 'numeric', // Добавляем год
            });
            acc[date] = acc[date] || [];
            acc[date].push(transaction);
            return acc;
        }, {});
    };

    useEffect(() => {
            document.body.style.backgroundColor = '#121212';
    }, []);

    const groupedTransactions = groupByDate(transactions);

    return (
        <div className="container-history">
            <h2>История транзакций</h2>
            {transactions.length === 0 && !loading && !hasMore && (
                <div className="no-transactions">
                    <p>Транзакции отсутствуют.</p>
                </div>
            )}
            {Object.keys(groupedTransactions).map((date) => (
                <div key={date}>
                    <div className="date-header">{date}</div>
                    {groupedTransactions[date].map((item, index) => (
                        <div key={index} className="transaction">
                            <div className="transaction-icon">⭐</div>
                            <div className="transaction-content">
                                <div className="transaction-path">{item.path}</div>
                                <div className="transaction-date">
                                    {new Date(item.date).toLocaleTimeString('ru-RU', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </div>
                            </div>
                            <div className="amounts">
                                <div className={item.getAmount >= 0 ? 'positive' : 'negative'}>
                                    {formatAmount(item.getAmount)} {item.get}
                                </div>
                                <div className="small-text">
                                    -{formatAmount(item.sendAmount)} {item.send}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            {loading && <p>Загрузка...</p>}
            {!hasMore && <p>Все транзакции загружены</p>}
        </div>
    );
};

export default TransactionHistory;

