import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import styles from "./depositInfo.module.css";
import VectorSvg from "./Vector.svg"; // Импорт SVG из той же папки
import { useTelegram } from "../hooks/useTelegram";

function InvoicePage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const invoiceID = decodeURIComponent(searchParams.get("invoiceID")); // Извлекаем как строку
    const network = decodeURIComponent(searchParams.get("network") || ""); // Извлекаем network

    const [invoiceData, setInvoiceData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [remainingMinutes, setRemainingMinutes] = useState(0);
    const [progress, setProgress] = useState(100);
    const [cryptocurrencies, setCryptocurrencies] = useState([]); // Для хранения списка криптовалют
    const [cryptoColor, setCryptoColor] = useState("#3a9fe5"); // Цвет по умолчанию
    const [isExpired, setIsExpired] = useState(false); // Новое состояние для истечения времени

    const qrCodeRef = useRef(null); // Для контейнера QR-кода
    const tg = useTelegram();

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru/profile";
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = "#2e3440";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    // Функция для удаления "-internal" из названия криптовалюты
    const cleanCryptoCode = (code) => {
        if (typeof code !== "string") return code;
        return code.replace(/-internal/i, ""); // Удаляем "-internal" (i для игнорирования регистра)
    };

    // Загружаем список криптовалют для получения цвета
    useEffect(() => {
        const fetchCryptocurrencies = async () => {
            try {
                const response = await fetch("https://api.just-trade.ru/api/cryptocurrencies");
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setCryptocurrencies(data);
            } catch (err) {
            }
        };
        fetchCryptocurrencies();
    }, []);

    // Устанавливаем цвет криптовалюты после загрузки данных
    useEffect(() => {
        if (invoiceData && cryptocurrencies.length > 0) {
            const cryptoCode = cleanCryptoCode(invoiceData.invoiceCurrencyCode); // Убираем "-internal" для поиска
            const crypto = cryptocurrencies.find(
                (c) => c.code.toLowerCase() === cryptoCode.toLowerCase()
            );
            if (crypto && crypto.color) {
                setCryptoColor(crypto.color);
            }
        }
    }, [invoiceData, cryptocurrencies]);

    const generateCryptoQRCode = (cryptoURI, imageUrl) => {
        if (!qrCodeRef.current) return;

        // Очистка предыдущего QR-кода
        qrCodeRef.current.innerHTML = "";

        // Проверка наличия QRCode
        if (!window.QRCode) {
            return;
        }

        // Генерация QR-кода с qrcode.js
        new QRCode(qrCodeRef.current, {
            text: cryptoURI,
            width: 280,
            height: 280,
            colorDark: "#3a9fe5",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
        });

        // Добавление логотипа как отдельного элемента
        const logoImg = document.createElement("img");
        logoImg.src = imageUrl;
        logoImg.style.position = "absolute";
        logoImg.style.top = "50%";
        logoImg.style.left = "50%";
        logoImg.style.transform = "translate(-50%, -50%)";
        logoImg.style.width = "80px";
        logoImg.style.height = "80px";
        logoImg.style.borderRadius = "1px";
        logoImg.style.backgroundColor = "#313b43";
        logoImg.style.border = "10px solid #ffffff";

        logoImg.onload = () => {
            qrCodeRef.current.appendChild(logoImg);
        };
        logoImg.onerror = () => {
        };
    };

    useEffect(() => {
        const fetchInvoiceData = async () => {
            try {
                const response = await fetch("https://api.just-trade.ru/get-invoice-data", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({ invoiceID }),
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(`HTTP error! status: ${response.status}, details: ${JSON.stringify(errorData)}`);
                }
                const result = await response.json();
                setInvoiceData(result);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };
        fetchInvoiceData();
    }, [invoiceID]);

    useEffect(() => {
        if (!invoiceData || !invoiceData.expirationTime || !invoiceData.createdAt) return;

        const expirationDate = new Date(invoiceData.expirationTime);
        const createdAt = new Date(invoiceData.createdAt);
        const totalDuration = expirationDate - createdAt;

        if (isNaN(expirationDate.getTime()) || isNaN(createdAt.getTime()) || totalDuration <= 0) {
            setError("Ошибка: некорректные даты в данных инвойса");
            return;
        }

        const updateTimer = () => {
            const now = new Date();
            const timeLeft = expirationDate - now;

            if (timeLeft <= 0) {
                setRemainingMinutes(0);
                setProgress(0);
                setIsExpired(true);
                return;
            }

            const minutesLeft = Math.ceil(timeLeft / (1000 * 60));
            const progressPercentage = Math.max(0, Math.min(100, (timeLeft / totalDuration) * 100));

            if (progressPercentage <= 0) {
                setRemainingMinutes(0);
                setProgress(0);
                setIsExpired(true);
            } else {
                setRemainingMinutes(minutesLeft);
                setProgress(progressPercentage);
                setIsExpired(false);
            }
        };

        updateTimer();
        const interval = setInterval(updateTimer, 5000);

        return () => clearInterval(interval);
    }, [invoiceData]);

    useEffect(() => {
        if (invoiceData && invoiceData.requisites && !isExpired) {
            generateCryptoQRCode(invoiceData.requisites, VectorSvg);
        } else if (isExpired && qrCodeRef.current) {
            qrCodeRef.current.innerHTML = "";
            const largeLogo = document.createElement("img");
            largeLogo.src = VectorSvg;
            largeLogo.className = styles.largeLogo;
            // Убираем border напрямую у изображения
            largeLogo.style.border = "none";
            qrCodeRef.current.appendChild(largeLogo);
        }
    }, [invoiceData, isExpired]);

    if (loading) return <p className={styles.loading}>Загрузка...</p>;
    if (error) return <p className={styles.error}>Ошибка: {error}</p>;
    if (!invoiceData) return <p className={styles.error}>Данные инвойса не найдены</p>;

    return (
        <div className={styles.invoiceContainer}>
            <h1 className={styles.title}>Пополнение</h1>
            <p className={styles.subtitle}>
                Отправьте только{" "}
                <span style={{ color: cryptoColor }}>
                    {cleanCryptoCode(invoiceData.invoiceCurrencyCode)}
                </span>{" "}
                через сеть{" "}
                <span style={{ color: "#ffffff" }}>{network || "не указана"}</span>,{" "}
                <span style={{ color: "#FC3F3F" }}>иначе монеты будут безвозвратно утеряны</span>
            </p>

            <div className={styles.timerContainer}>
                <p>Время на оплату: {remainingMinutes} минут</p>
                <div className={styles.progressBar}>
                    <div
                        className={styles.progressFill}
                        style={{
                            width: `${progress}%`,
                            backgroundColor: progress >= 70 ? '#00C853' : progress >= 30 ? '#FFCA28' : '#D32F2F',
                        }}
                    ></div>
                </div>
            </div>

            <div className={styles.qrCodeContainer}>
                <div ref={qrCodeRef} className={`${styles.qrCodeWrapper} ${isExpired ? styles.expired : ''}`}></div>
            </div>

            <div className={styles.amountContainer}>
                <p className={styles.label}>Минимальная сумма:</p>
                <p className={styles.amount}>
                    {invoiceData.invoiceAmount}{" "}
                    <span style={{ color: cryptoColor }}>
                        {cleanCryptoCode(invoiceData.invoiceCurrencyCode)}
                    </span>
                </p>
            </div>

            <div className={styles.addressContainer}>
                <p className={styles.label}>Адрес для перевода:</p>
                {isExpired ? (
                    <p className={styles.expiredMessage}>Время истекло, создайте новую заявку</p>
                ) : (
                    <>
                        <p className={styles.address}>{invoiceData.requisites}</p>
                        <button
                            className={styles.copyButton}
                            onClick={() => {
                                navigator.clipboard.writeText(invoiceData.requisites);
                                alert("Адрес скопирован!");
                            }}
                        >
                            Скопировать адрес
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}

export default InvoicePage;