import React, { useEffect, useState } from 'react';
import "./css.css";
import { useTelegram } from "../hooks/useTelegram";
import { ReactComponent as LogoIcon } from './Vector.svg';
import FooterMenu from '../controlMenu/controlMenu'; // Импортируем новый компонент
// import Icons from './Icons'; // Reusable Icons component


function RefPage() {
    const tg = useTelegram();
    const userId = tg?.user?.id || '0';
    const [balance, setBalance] = useState(null); // Храним баланс пользователя
    const [error, setError] = useState(null); // Храним ошибки
    const [photoUrl, setPhotoUrl] = useState(null);
    const [name, setName] = useState(null);
    // Function to copy referral link

    useEffect(() => {
        // Асинхронная функция для получения URL фото
        const getPhotoUrl = async () => {

            try {
                // Выполняем запрос к API
                const response = await fetch(`https://api.just-trade.ru/get-photo-url?appDataInit=${tg.saveData}`);
                
                // Проверяем, был ли успешен запрос
                if (!response.ok) {
                    throw new Error('Ошибка при запросе к API');
                }

                // Получаем данные из ответа
                const data = await response.json();

                // Извлекаем photo_url и сохраняем в стейт
                setPhotoUrl(data.photo_url);
                setName(data.name);
            } catch (error) {
                
            }
        };

        // Вызываем функцию для получения данных
        getPhotoUrl();
    }, []); 

    const handleCopyReferral = () => {
        navigator.clipboard.writeText(`https://t.me/JusTrade_bot?start=${userId}`); // Copy "123" to clipboard
        alert('Реферальная ссылка скопирована в буфер обмена!');
    };

    const handleBackClick = () => {
        window.location.href = window.location.origin; // Переход на just-trade.ru
    };
        
    useEffect(() => {
        if (tg.BackButton) {
            // Показываем кнопку "Назад" в Telegram WebApp
            tg.BackButton.show();

            // Устанавливаем обработчик клика на кнопку
            tg.BackButton.onClick(handleBackClick);
        }
    }, [tg]);

    // Функция для запроса баланса
    const fetchBalance = async () => {
        try {
            const initData = tg.saveData; // Данные инициализации Telegram WebApp
            const hash = tg.saveData; // Хэш данных

            const response = await fetch('https://api.just-trade.ru/get_user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    initData: initData,
                    hash: hash,
                }),
            });

            const data = await response.json();

            if (response.ok) {
                setBalance(data.user.balance); // Устанавливаем баланс из ответа
            } else {
                setError(data.error || 'Не удалось получить баланс');
            }
        } catch (err) {
            setError('Произошла ошибка при получении данных');
        }
    };
    useEffect(() => {
        document.body.style.background = "linear-gradient(rgba(15,106,189,1) 0%, rgba(0,0,0,1) 81%)";
    }, []);
    // Выполняем запрос при монтировании компонента
    useEffect(() => {
        fetchBalance();
    }, []);

    // Function to send a withdrawal request
    const handleWithdrawBalance = async () => {
        try {
            const initDataa = tg.saveData;
            const response = await fetch('https://api.just-trade.ru/withdraw', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ initDataa: initDataa }) // Отправляем initData на сервер
            });
    
            if (response.ok) {
                const data = await response.json();
                if (data.valid) {
                    alert('Заявка на вывод передана в обработку');
                    fetchBalance();
                } else {
                    alert('Минимальный вывод 1.1$');
                }
            } else {
                alert('Минимальный вывод 1.1$');
            }
        } catch (error) {
            alert('Произошла ошибка!');
        }
    };

    return (
        
        <div className="main-ref">
            <div className="Logo">
                <LogoIcon className="LogoClass" />
            </div>
        <div className="profile-section-ref">
            <img
                src={photoUrl} // Используем фото пользователя или заглушку
                alt="Profile"
                className="profile-image"
            />
            <h2 className="profile-text">{name || 'Загрузка...'}</h2>
        </div>
        {error ? (
                <h1 className="balance-error">{error}</h1>
            ) : (
                <h1 className="balance">${balance !== null ? balance.toFixed(3) : '...'}</h1>
            )}

        {/* Buttons */}
        <button className="bt referral-btn" onClick={handleCopyReferral}>
            ПОДЕЛИТЬСЯ РЕФ. ССЫЛКОЙ
        </button>
        <button className="btn withdraw-btn" onClick={handleWithdrawBalance}>
            ВЫВОД РЕФ. БАЛАНСА
        </button>

        {/* <FooterMenu /> */}
        </div>
    );
};

export default RefPage;