import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./deposit.module.css";
import { useTelegram } from "../hooks/useTelegram";

function CryptoList() {
    const [cryptocurrencies, setCryptocurrencies] = useState([]);
    const [filteredCryptocurrencies, setFilteredCryptocurrencies] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [networks, setNetworks] = useState([]);
    const [networkLoading, setNetworkLoading] = useState(false);
    const [networkError, setNetworkError] = useState(null);
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false); // Новое состояние для создания инвойса
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const selectedCrypto = searchParams.get("crypto");
    const selectedNetwork = searchParams.get("network");

    const networksContainerRef = useRef(null);

    const tg = useTelegram();

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru/profile";
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
        return () => {
            if (tg.BackButton) tg.BackButton.hide();
        };
    }, [tg]);

    useEffect(() => {
        document.body.style.backgroundColor = "#2e3440";
        return () => {
            document.body.style.backgroundColor = "";
        };
    }, []);

    useEffect(() => {
        const fetchCryptocurrencies = async () => {
            try {
                const response = await fetch("https://api.just-trade.ru/api/cryptocurrencies");
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();
                setCryptocurrencies(data);
                setFilteredCryptocurrencies(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchCryptocurrencies();
    }, []);

    useEffect(() => {
        const fetchNetworks = async () => {
            if (!selectedCrypto) return;

            setNetworkLoading(true);
            setNetworkError(null);

            try {
                console.log("Sending crypto:", selectedCrypto);
                const response = await fetch("https://api.just-trade.ru/api/networks", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ crypto: selectedCrypto }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setNetworks(data);
            } catch (err) {
                setNetworkError(err.message);
            } finally {
                setNetworkLoading(false);
            }
        };

        fetchNetworks();
    }, [selectedCrypto]);

    useEffect(() => {
        if (networks.length > 0 && networksContainerRef.current) {
            networksContainerRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [networks]);

    useEffect(() => {
        if (searchQuery.trim() === "") {
            setFilteredCryptocurrencies(cryptocurrencies);
        } else {
            const lowerQuery = searchQuery.toLowerCase();
            const filtered = cryptocurrencies.filter(
                (crypto) =>
                    crypto.code.toLowerCase().includes(lowerQuery) ||
                    crypto.name.toLowerCase().includes(lowerQuery)
            );
            setFilteredCryptocurrencies(filtered);
        }
    }, [searchQuery, cryptocurrencies]);

    const handleCryptoClick = (code) => {
        navigate(`/deposit?crypto=${code}`);
    };

    const handleNetworkClick = async (networkCode) => {
        console.log("Selected network:", networkCode);

        // Показываем уведомление и скрываем сети
        setIsCreatingInvoice(true);

        try {
            // Подготовка данных для создания инвойса
            const invoiceData = {
                currencyInCode: selectedCrypto,
                invoiceAssetCode: networkCode,
                comment: tg.user.id.toString(),
                publicComment: "",
            };

            // Отправка POST-запроса на сервер
            const response = await fetch("https://api.just-trade.ru/create-invoice", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(invoiceData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
            }

            const result = await response.json();

            // Обработка успешного ответа
            if (result.success) {
                navigate(`/invoice?invoiceID=${encodeURIComponent(result.invoiceID)}&network=${encodeURIComponent(networkCode)}`);
            } else {
                throw new Error(result.error || "Не удалось создать инвойс");
            }
        } catch (err) {
            console.error("Ошибка при создании инвойса:", err.message);
            setNetworkError(err.message);
            setIsCreatingInvoice(false); // Возвращаем сети в случае ошибки
        }
    };

    const getCryptoColor = () => {
        if (!selectedCrypto || !cryptocurrencies.length) return "white";
        const crypto = cryptocurrencies.find((c) => c.code === selectedCrypto);
        return crypto ? crypto.color : "white";
    };

    if (loading) return <p className={styles.loading}>Загрузка...</p>;
    if (error) return <p className={styles.error}>Ошибка: {error}</p>;

    return (
        <div className={styles.cryptoListContainer}>
            {!selectedNetwork && (
                <div>
                    <h1 className={styles.title}>Выберите криптовалюту для пополнения</h1>
                    <input
                        type="text"
                        placeholder="Поиск по коду или названию..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className={styles.searchInput}
                    />
                    <div className={styles.cryptoContainer}>
                        {filteredCryptocurrencies.length === 0 ? (
                            <p className={styles.noResults}>Криптовалюты не найдены</p>
                        ) : (
                            filteredCryptocurrencies.map((crypto, index) => (
                                <div
                                    key={crypto.id || `${crypto.code}-${index}`}
                                    className={styles.cryptoItem}
                                    onClick={() => handleCryptoClick(crypto.code)}
                                >
                                    <img
                                        src={crypto.icon}
                                        alt={crypto.code}
                                        className={styles.cryptoIcon}
                                        onError={(e) => {
                                            e.target.src = "https://via.placeholder.com/40";
                                            e.target.style.width = "2.5rem";
                                            e.target.style.height = "2.5rem";
                                        }}
                                    />
                                    <div className={styles.cryptoInfo}>
                                        <span className={styles.cryptoCode} style={{ color: crypto.color }}>
                                            {crypto.code}
                                        </span>
                                        <span className={styles.cryptoName}>{crypto.name}</span>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            )}
            {(selectedCrypto || selectedNetwork) && (
                <div ref={networksContainerRef}>
                    <h2 className={styles.title}>
                        Выберите сеть для <span style={{ color: getCryptoColor() }}>{selectedCrypto}</span>
                    </h2>
                    {isCreatingInvoice ? (
                        <p className={styles.loading}>
                            <span className={styles.spinner}></span> Создание инвойса, ожидайте...
                        </p>
                    ) : (
                        <>
                            {networkLoading && <p className={styles.loading}>Загрузка сетей...</p>}
                            {networkError && <p className={styles.error}>Ошибка: {networkError}</p>}
                            {!networkLoading && !networkError && (
                                <div className={styles.networkContainer}>
                                    {networks.length === 0 ? (
                                        <p className={styles.noResults}>Сети не найдены</p>
                                    ) : (
                                        networks.map((network) => (
                                            <div
                                                key={network.network_id}
                                                className={styles.networkItem}
                                                onClick={() => handleNetworkClick(network.network_code)}
                                            >
                                                <img
                                                    src={network.icon}
                                                    alt={network.network_code}
                                                    className={styles.cryptoIcon}
                                                    onError={(e) => {
                                                        e.target.src = "https://via.placeholder.com/40";
                                                        e.target.style.width = "2.5rem";
                                                        e.target.style.height = "2.5rem";
                                                    }}
                                                />
                                                <div className={styles.cryptoInfo}>
                                                    <span className={styles.cryptoCode}>{network.network_code}</span>
                                                    <span className={styles.cryptoName}>{network.network_name}</span>
                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default CryptoList;