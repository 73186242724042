import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom'; // Добавляем Link для перехода
import '../exchangePage/css.css';
import { useTelegram } from '../hooks/useTelegram';

function BalanceExchangePage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const sendCryptoID = queryParams.get('sendCryptoID');
    const getCryptoID = queryParams.get('getCryptoID');
    const exchangePath = queryParams.get('exchangePath'); // Добавляем exchangePath для совместимости
    const tg = useTelegram();

    const [exchangeData, setExchangeData] = useState({ sendCrypto: null, getCrypto: null });
    const [amountCrypto, setAmountCrypto] = useState(0);
    const [amountAfterFee, setAmountAfterFee] = useState(0);
    const [balanceError, setBalanceError] = useState('');
    const [userBalance, setUserBalance] = useState(0);
    const inputCryptoRef = useRef(null);
    const spanCryptoRef = useRef(null);

    const handleBackClick = () => {
        window.location.href = "https://just-trade.ru/profile"; // Переход на just-trade.ru
    };

    useEffect(() => {
        if (tg.BackButton) {
            tg.BackButton.show();
            tg.BackButton.onClick(handleBackClick);
        }
    }, [tg]);

    // Загружаем данные о криптовалютах
    const loadCryptoData = async (cryptoID, type) => {
        try {
            const response = await fetch(`https://api.just-trade.ru/api/wallet/crypto/${cryptoID}`);
            if (!response.ok) throw new Error('Криптовалюта не найдена');
            const data = await response.json();
            const { name, icon, rate, code } = data; // Заменяем symbol на code
            console.log(icon)

            setExchangeData(prevData => ({
                ...prevData,
                [type]: {
                    name,
                    url: icon,
                    rate: rate || 0,
                    code, // Используем code вместо symbol
                },
            }));
        } catch (error) {
            console.error('Ошибка при загрузке данных криптовалюты:', error);
        }
    };

    // Загружаем баланс пользователя
    const loadUserBalance = async () => {
        try {
            const response = await fetch(
                `https://api.just-trade.ru/api/user_balances?user_id=${tg.user.id}&crypto_id=${sendCryptoID}`
            );
            if (!response.ok) throw new Error('Ошибка при получении баланса');
            const data = await response.json();
            setUserBalance(data.amount || 0);
        } catch (error) {
            console.error('Ошибка при загрузке баланса:', error);
            setBalanceError('Не удалось загрузить баланс.');
        }
    };

    // Загружаем данные криптовалют и баланс
    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([
                loadCryptoData(sendCryptoID, 'sendCrypto'),
                loadCryptoData(getCryptoID, 'getCrypto'),
                loadUserBalance(),
            ]);

            if (exchangeData.sendCrypto?.rate && exchangeData.getCrypto?.rate) {
                const conversionRate = exchangeData.sendCrypto.rate / exchangeData.getCrypto.rate;
                const newAmountAfterFee = calculateFee(amountCrypto * conversionRate);
                setAmountAfterFee(newAmountAfterFee);
            }
        };

        if (sendCryptoID && getCryptoID) {
            fetchData();
        }
    }, [sendCryptoID, getCryptoID, amountCrypto]);

    // Обработчик изменения суммы
    const handleCryptoChange = (e) => {
        let value = e.target.value;
        if (value.includes(',')) value = value.replace(',', '.');
        const numericValue = parseFloat(value);

        if (!isNaN(numericValue) && /^[0-9]*\.?[0-9]*$/.test(value)) {
            setAmountCrypto(value);

            if (numericValue > userBalance) {
                setBalanceError(
                    `Недостаточно средств на балансе. Доступно: ${userBalance} ${exchangeData.sendCrypto?.code || ''}`
                );
            } else {
                setBalanceError('');
            }

            if (exchangeData.sendCrypto?.rate && exchangeData.getCrypto?.rate) {
                const conversionRate = exchangeData.sendCrypto.rate / exchangeData.getCrypto.rate;
                const newAmountAfterFee = calculateFee(numericValue * conversionRate);
                setAmountAfterFee(newAmountAfterFee);
            }
        } else {
            setAmountCrypto(0);
            setAmountAfterFee(0);
            setBalanceError('');
        }

        adjustInputWidth(value, inputCryptoRef, spanCryptoRef);
    };

    // Логика комиссии
    const calculateFee = (amount) => {
        const feePercentage = 5; // Пример комиссии 5%
        let adjustedAmount = amount * (1 - feePercentage / 100);

        if (exchangeData.getCrypto?.rate >= 1000) {
            adjustedAmount = parseFloat(adjustedAmount.toFixed(8));
        } else if (exchangeData.getCrypto?.rate >= 100) {
            adjustedAmount = parseFloat(adjustedAmount.toFixed(5));
        } else {
            adjustedAmount = parseFloat(adjustedAmount.toFixed(2));
        }

        return adjustedAmount;
    };

    // Обработчик обмена
    const handleExchange = async () => {
        if (balanceError) {
            alert('Исправьте ошибки перед обменом.');
            return;
        }

        if (amountCrypto <= 0) {
            alert('Введите сумму для обмена.');
            return;
        }

        const payload = {
            user_id: 1151872690,
            sendCryptoID,
            getCryptoID,
            sendAmount: parseFloat(amountCrypto),
            receiveAmount: amountAfterFee,
            initData: tg.saveData
        };

        try {
            const response = await fetch('https://api.just-trade.ru/api/exchange_balance', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            const data = await response.json();
            if (data.error) {
                throw new Error(data.error);
            }

            alert('Обмен успешно завершен!');
            setUserBalance(userBalance - parseFloat(amountCrypto));
            setAmountCrypto(0);
            setAmountAfterFee(0);
        } catch (error) {
            console.error('Ошибка при обмене:', error);
            alert(`Ошибка: ${error.message}`);
        }
    };

    // Адаптация ширины поля ввода
    const adjustInputWidth = (value, inputRef, spanRef) => {
        if (spanRef.current && inputRef.current) {
            spanRef.current.textContent = value;
            const spanWidth = spanRef.current.offsetWidth;
            inputRef.current.style.width = `${spanWidth + 15}px`;
        }
    };

    useEffect(() => {
        document.body.style.backgroundColor = '#313B43';
        adjustInputWidth(amountCrypto, inputCryptoRef, spanCryptoRef);
    }, [amountCrypto, exchangeData]);

    return (
        <div className="containerEXCHNAGER">
            {exchangeData.sendCrypto && exchangeData.getCrypto && (
                <div className="exchangeBox">
                    <h3 className="title" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={exchangeData.sendCrypto.url} alt={exchangeData.sendCrypto.code} style={{ width: '30px', marginRight: '5px' }} />
                        <span>{exchangeData.sendCrypto.code} ↔️ {exchangeData.getCrypto.code}</span>
                        <img src={exchangeData.getCrypto.url} alt={exchangeData.getCrypto.code} style={{ width: '30px', marginLeft: '5px' }} />
                    </h3>
                    <div className="inputGroup">
                        <label className="label">Отправляете (Баланс: {userBalance} {exchangeData.sendCrypto?.code})</label>
                        <div className="inputRow">
                            <div className="cryptoInfo">
                                <Link
                                    to={`/balanceCryptoDetails?exchangePath=${exchangePath}&sendCryptoID=${sendCryptoID}&getCryptoID=${getCryptoID}&change=send`}
                                    className="cryptoLink"
                                >
                                    <img src={exchangeData.sendCrypto.url} alt={sendCryptoID} className="iconImage" />
                                    <span className="cryptoName">{exchangeData.sendCrypto.code}</span>
                                </Link>
                            </div>
                            <input
                                type="text"
                                value={amountCrypto}
                                onChange={handleCryptoChange}
                                className="inputCrypto"
                                ref={inputCryptoRef}
                                style={{ minWidth: '80px' }}
                            />
                            <span ref={spanCryptoRef} className="hidden-span"></span>
                        </div>
                        {balanceError && <span className="error-text">{balanceError}</span>}
                    </div>
                    <div className="outputGroup">
                        <label className="label">Получаете</label>
                        <div className="inputRow">
                            <div className="cryptoInfo">
                                <Link
                                    to={`/balanceCryptoDetails?exchangePath=${exchangePath}&sendCryptoID=${sendCryptoID}&getCryptoID=${getCryptoID}&change=get`}
                                    className="cryptoLink"
                                >
                                    <img src={exchangeData.getCrypto.url} alt={getCryptoID} className="iconImage" />
                                    <span className="cryptoName">{exchangeData.getCrypto.code}</span>
                                </Link>
                            </div>
                            <input
                                type="text"
                                value={amountAfterFee}
                                readOnly
                                className="inputUSDT"
                                style={{ minWidth: '80px' }}
                            />
                        </div>
                    </div>
                </div>
            )}
            <div className="buttonContainer">
                <button className="button" onClick={handleExchange}>
                    ОБМЕНЯТЬ
                </button>
            </div>
        </div>
    );
}

export default BalanceExchangePage;